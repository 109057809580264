const DemoPage = () => {
    
    return (
        <section>
        <h2 className="callHeading">Call Me.</h2>
        <div className="callHeader">
        <form>
            <label for="phone-number">
            Please enter your name:
            </label>
            <input id="phone-number" type="text" placeholder="First name" />
            <button id="startup-button" type="submit" class="big-button">Request to Call Max</button>
      </form>
      </div>
        <div className="callMain">
                <section class="left-column" id="info">
                <h2>Your Device Info</h2>
                <div id="output-selection" class="hide">
                <label>Ringtone Devices</label>
                <select id="ringtone-devices" multiple></select>
                <label>Speaker Devices</label>
                <select id="speaker-devices" multiple></select><br />
                <button id="get-devices">Seeing "Unknown" devices?</button>
                </div>
            </section>
            <section class="center-column">
                <h2 class="instructions">Make a Call</h2>
                <span id="wait" class="hide">Requesting Call... Please wait...</span>
                <div id="call-controls" class="hide">
                <form>
                    <button id="button-call" class="big-button" type="submit">Start Call</button>
                </form>
                <button id="button-hangup-outgoing" class="big-button hide">Hang Up</button>
                <div id="volume-indicators" class="hide">
                    <label>Mic Volume</label>
                    <div id="input-volume"></div>
                    <br /><br />
                    <label>Speaker Volume</label>
                    <div id="output-volume"></div>
                </div>
                </div>
            </section>
            <section class="right-column">
            </section>
        </div>



      </section>
    );
}

export default DemoPage;