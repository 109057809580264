import { Link } from "react-router-dom";
export default function Footer(){

    return (
        <footer>
            <h3>Software Adventures</h3>
            <div className="footerBtns">
                <button>Home</button>
                <button>Terms of Service</button>
                <button>Privacy Policy</button>
            </div>
        </footer>
      );
}