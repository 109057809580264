import { Link } from "react-router-dom";
import PhoneOutline from 'mdi-react/PhoneForwardOutlineIcon'
export default function Header(){

    return (
        <header>
          <nav>
            <div className="navbar">
            <div className="logoDiv">
            <PhoneOutline size={50}/>
            <h2>Call Me</h2>
            </div>
            <ul>
              <li>
              <Link to="/" className="links">Home</Link>
              </li>
              <li>
              <Link to="/pricing" className="links">Pricing</Link>
              </li>
              <li>
              <Link to="/demo" className="links">Demo</Link>
              </li>
              <li><Link to="/login"><button>Log In</button></Link></li>
            </ul>
            </div>
          </nav>
        </header>
      );
}