// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0


import { CognitoIdentityProviderClient, InitiateAuthCommand, SignUpCommand, ConfirmSignUpCommand, AuthFlowType, ChallengeName, RespondToAuthChallengeCommand } from "@aws-sdk/client-cognito-identity-provider";
import { SRPClient, getNowString, calculateSignature } from 'amazon-user-pool-srp-client';

import config from "./config.json";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

export const signIn = async (username: string, password: string) => {

  const srp = new SRPClient(config.userPoolId);

  const SRP_A = srp.calculateA();

  const params = {
    AuthFlow: "USER_SRP_AUTH" as AuthFlowType,
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      SRP_A: SRP_A,
    },  
  };
  try {
    const command = new InitiateAuthCommand(params);

    const { ChallengeName, ChallengeParameters } = await cognitoClient.send(command);
    

    if (ChallengeName === "PASSWORD_VERIFIER" && ChallengeParameters) {

      const date = getNowString();

      const hkdf = srp.getPasswordAuthenticationKey(ChallengeParameters!.USERNAME, password, ChallengeParameters!.SRP_B, ChallengeParameters!.SALT);
      const signature = calculateSignature(hkdf, config.userPoolId, ChallengeParameters!.USERNAME, ChallengeParameters!.SECRET_BLOCK, date);

      const respondToAuthChallengeParams = {
        ClientId: config.clientId,
        ChallengeName: ChallengeName,
        ChallengeResponses: {
          PASSWORD_CLAIM_SIGNATURE: signature,
          PASSWORD_CLAIM_SECRET_BLOCK: ChallengeParameters!.SECRET_BLOCK,
          TIMESTAMP: date,
          USERNAME: username
        },
      };

      const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand(respondToAuthChallengeParams);

      const { AuthenticationResult } = await cognitoClient.send(respondToAuthChallengeCommand);

    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
      return AuthenticationResult;
    }

    } 
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const signUp = async (email: string, password: string) => {
  const params = {
    ClientId: config.clientId,
    Username: email,
    Password: password,
    UserAttributes: [
      {
        Name: "email",
        Value: email,
      },
    ],
  };
  try {
    const command = new SignUpCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Sign up success: ", response);
    return response;
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};

export const confirmSignUp = async (username: string, code: string) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
    ConfirmationCode: code,
  };
  try {
    const command = new ConfirmSignUpCommand(params);
    await cognitoClient.send(command);
    console.log("User confirmed successfully");
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};
