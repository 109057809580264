import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from './Header';
import Layout from "./Layout";
import IndexPage from './Pages/IndexPage';
import PricingPage from './Pages/PricingPage';
import DemoPage from './Pages/DemoPage';
import LoginPage from './Pages/LoginPage.tsx';
import ConfirmUserPage from './Pages/ConfirmUserPage.tsx';
import HomePage from './Pages/HomePage.tsx';
import SuccessPage from './Pages/SuccessPage.js';

const App = () => {

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const isAuthenticated = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return !!accessToken;
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);


  return (
    
    <Routes>
      <Route path='/' element={<Layout />}>
      <Route index element={<IndexPage />} />
      <Route path={'/pricing'} element={<PricingPage />} />
      <Route path={'/demo'} element={<DemoPage />} />
      <Route path={'/login'} element={<LoginPage />} />
      <Route path={'/confirm'} element={<ConfirmUserPage />} />
      <Route path={'/home'} element={isAuthenticated() ? <HomePage /> : <Navigate replace to="/login" />} />
      <Route path={'/success'} element={<SuccessPage />} />
      </Route>
    </Routes>

  );
}

export default App;
