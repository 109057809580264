import TickBox from 'mdi-react/TickCircleIcon';
import CloseBox from 'mdi-react/CloseCircleIcon';
import StarIcon from 'mdi-react/StarFourPointsOutlineIcon';
import BuildingIcon from 'mdi-react/AccountTieIcon';

const PricingPage = () => {
    
    return (
        <section>
            <h1 className="pricingHeader">Pick your subscription.</h1>
            <div className="row">
                <div className="column">
                    <div className="planHead">
                    <h2>Free</h2>
                    </div>
                    <div className="pricingContents">
                        <p className="planInfo"><span className="bold">For individual</span> use. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac.</p>
                        <p>Custom Domain Support <CloseBox className='checkIcon' color='red'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <div className="priceBox">
                            <p className='price'>A$ 0.00</p>
                            <p className='priceAlt'>Per month.</p>
                            <p>Free to use.</p>
                        </div>
                        <div className='btnDivide'>
                        <button>Buy Now</button>
                        </div> 
                    </div>
                    
                </div>
                <div className="column">
                    <div className="planHead">
                    <StarIcon className='icon'/> 
                    <h2>Pro</h2>
                    </div>
                    <div className="pricingContents">
                        <p className="planInfo"><span className='bold'>For individual</span> use with a range of pro features. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac.</p>
                        <p>Custom Domain Support <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <div className="priceBox">
                            <p className='price'>A$ 5.00</p>
                            <p className='priceAlt'>Per month.</p>
                            <p>Billed monthly.</p>
                        </div>
                        <div className='btnDivide'>
                        <form action="http://localhost:4242/create-checkout-session" method="POST">
                        {/* Add a hidden field with the lookup_key of your Price */}
                        <input type="hidden" name="lookup_key" value="pro_tier" />
                        <button id="checkout-and-portal-button" type="submit">
                        Buy Now
                        </button>
                        </form>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="planHead">
                    <BuildingIcon className='icon'/>
                    <h2>Business</h2>
                    </div>
                    <div className="pricingContents">
                        <p className="planInfo"><span className="bold">For all team sizes</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac.</p>
                        <p>Custom Domain Support <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <p>Placeholder <TickBox className='checkIcon' color='green'/></p>
                        <div className="priceBox">
                            <p className='price'>A$ 0.00</p>
                            <p className='priceAlt'>Per month.</p>
                            <p>Billed monthly.</p>
                        </div>
                        <div className='btnDivide'>
                        <button>Buy Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    );
}

export default PricingPage;