const IndexPage = () => {
    
    return (


        <>
        <div className="imageheader">
        Placeholder image can go here/ text with headings and subheadings
        </div>

        <section>

            <div className="indexRow">
                <div className="indexColumn">
                <div className="aboutTab">
                    <h2>About Call Me</h2>
                </div>
                <div className="aboutInfo">
                    Loren Ipsum
                </div>
                </div>
                <div className="indexColumn">
                <div className="aboutTab">
                    <h2>Something Here</h2>
                </div>
                <div className="aboutInfo">
                    Loren Ipsum
                </div>
                </div>
            </div>
        </section>

        </>
    );
}

export default IndexPage;